import firebase from 'firebase/app';
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: 'AIzaSyAgMSs9R9Z7t5CNxbLaooF9wmmP_sGIk4E',
//   authDomain: 'enter-prof.firebaseapp.com',
//   projectId: 'enter-prof',
//   storageBucket: 'enter-prof.appspot.com',
//   messagingSenderId: '570389601262',
//   appId: '1:570389601262:web:3398b58ad8717d3cde0cff',
//   measurementId: 'G-F9LQZ4CS40',
// }
// See: https://firebase.google.com/docs/web/learn-more#config-object
// const firebaseConfig = {
//   // ...
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

const storage = firebase.storage();

// Initialize Firebase Authentication and get a reference to the service
// const auth = getAuth(app);

export { storage, firebase as default };
